import React, { useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";

import {
  EmployerScreen,
  ForgotPasswordScreen,
  HomePage,
  LoginScreen,
  PartnerScreen,
  SignUpScreen1,
  SignUpScreen2,
  SignUpScreenOTP,
  TalentScreen,
  VerifyEmailScreen,
} from "./containers";
import { AnimatePresence } from "framer-motion";
import { scrollToTop } from "./utils/scrollToTop";
import PrivacyPolicyScreen from "./containers/PrivacyPolicyScreen/PrivacyPolicyScreen";
import TermsAndConditionsScreen from "./containers/TermsAndConditionsScreen/TermsAndConditionsScreen";

Sentry.init({
  dsn: "https://ce24f1d1b0acc730792e650235987016@o4508773878988800.ingest.us.sentry.io/4509091230973952",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration()
  ],
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0
});

const GTMNoScript = () => (
  <noscript>
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-TRDFZDSJ"
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    ></iframe>
  </noscript>
);

const App = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  return (
    <>
      <div className="app">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HomePage />} />
            <Route path="/talent" element={<TalentScreen />} />
            <Route path="/employer" element={<EmployerScreen />} />
            <Route path="/partner" element={<PartnerScreen />} />
            <Route path="/signup" element={<SignUpScreen2 />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditionsScreen />} />
          </Routes>
        </AnimatePresence>
      </div>
      <GTMNoScript />
    </>
  );
};

export default App;
